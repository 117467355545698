import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import TLinkApi from "services/tlink.api";
import Axios from "axios";
import Auth from "services/auth";
import { useSnackbar } from "notistack";
import { Grid, IconButton, TextField } from "@mui/material";
import PageLayout from "comps/LayoutContainers/PageLayout";
import "./index.css";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Controller, useForm } from "react-hook-form";
import LoginImage from "assets/images/stepperSvg/op-image.png"; 
import buzz_logo from "assets/images/stepperSvg/logo2.png"; 
import { InputAdornment } from "@mui/material";

function Basic() {
  const { enqueueSnackbar } = useSnackbar();
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: { email: "", password: "" },
  });

  const save = async (value) => {
    try {
      const { data, status } = await Axios.post(
        TLinkApi.baseUrl + "/login",
        value
      );
      if (status === 200) {
        Auth.saveAuthorizationToken(data?.token);
        enqueueSnackbar("Logged in", {
          variant: "success",
        });
        navigate("/dashboard");
      }
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
      });
    }
  };

  return (
    <PageLayout>
      <Grid
        container
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Grid
          item
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none;",
              lg: "block",
              xl: "block",
            },
          }}
          xs={10}
          md={12}
          lg={7}
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            overflow: "hidden",
            backgroundPosition: "center",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={LoginImage} width="70%" />=
          </div>
        </Grid>
        <Grid item xs={2} md={12} lg={5}>
          <MDBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="left"
            sx={{ height: "100vh" }}
          >
            <MDBox
              variant="gradient"
              mx={2}
              p={2}
              mb={1}
              textAlign="center"
              fontWeight="400"
              sx={{
                width: "70%",
                height: "65%",
                boxShadow: "0px 1px 40px 0px rgba(0, 0, 0, 0.25)",
                backgroundColor: "rgba(255, 255, 255, 0.50)",
              }}
            >
              <MDBox
                component="img"
                mt={4}
                mb={2}
                src={buzz_logo}
                alt="Admin App"
                width="100px"
                height="100px"
                background="#00AEEF"
              />
              <MDBox textAlign="center">
                <h5>OPINIOBOX</h5>
              </MDBox>
              <MDBox textAlign="left" pt={1} pb={3} px={3}>
                <form onSubmit={handleSubmit(save)} noValidate>
                  <MDBox mb={3} mt={3}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email is required!",
                        validate: {
                          matchPattern: (v) =>
                            /\S+@\S+\.\S+/.test(v) ||
                            "Email address must be a valid address",
                        },
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          type="email"
                          id="email"
                          label="Email"
                          {...register("email")}
                          variant="outlined"
                          error={errors?.email ? true : false}
                          helperText={
                            errors?.email ? errors.email.message : null
                          }
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox mb={2} className="passwordInput">
                    <Controller
                      name="password"
                      control={control}
                      rules={{
                        required: "Password is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          id="password"
                          label="Password"
                          {...register("password")}
                          type={passwordShown ? "text" : "password"}
                          variant="outlined"
                          error={errors?.password ? true : false}
                          helperText={
                            errors?.password ? errors.password.message : null
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordShown((show) => !show)
                                  }
                                  onMouseDown={(event) => {
                                    event.preventDefault();
                                  }}
                                  edge="end"
                                >
                                  {passwordShown ? (
                                    <VisibilityOffOutlinedIcon color="secondary" />
                                  ) : (
                                    <VisibilityOutlinedIcon color="secondary" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </MDBox>

                  <MDBox
                    mt={5}
                    // mb={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <MDButton
                      type="submit"
                      variant="contained"
                      fullWidth
                      style={{
                        backgroundColor: "#5885BC",
                        color: "white",
                        width: "100%",
                        "&:hover": {
                          border: "5px solid rgba(255, 255, 255, 0.57)",
                        },
                      }}
                    >
                      Login
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Basic;
