import {
  AppBar,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  Toolbar,
} from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "comps/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Auth from "services/auth";
import PrincipalState from "services/principal.state";
import TLinkApi from "services/tlink.api";
import { useNavigate } from "react-router-dom";
import SearchInput from "components/searchInput";

import {
  useMaterialUIController,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";

const PrivateOutlet = ({ setWidth, width, setMenuOpen, menuOpen }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, darkMode } = controller;

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const fetchSearchResults = async () => {
    try {
      const response = await TLinkApi.get(`/global/?search=${search}`);

      setSearchResults(response.data.search);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (search.length > 3) {
      fetchSearchResults();
    } else if (search.length === 0) {
      setSearchResults({});
    }
    // eslint-disable-next-line
  }, [search]);

  const authorize = async () => {
    try {
      await Auth.authorize();
      let data = PrincipalState.getIdentity();
      if (data) setCurrentUser(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    authorize();
    // eslint-disable-next-line
  }, []);

  let light = false;

  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <DashboardLayout setWidth={setWidth} width={width}>
      <MDBox mb={2}>
        <AppBar position="static">
          <Toolbar
            style={{
              padding: "0px",
              margin: "0px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            ></IconButton> */}
            <Stack direction="row" spacing={2} width="80%">
              <SearchInput
                dashbord={true}
                setSearch={setSearch}
                search={search}
                setMenuOpen={setMenuOpen}
              />
              <MDBox
                position="absolute"
                bgColor="#ffff"
                zIndex="3"
                width="38%"
                top="60px"
                left="16px"
                maxHeight="200px"
                style={{
                  overflowY: "auto",
                  boxShadow:
                    " 0rem 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.1), 0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05)",
                }}
              >
                {Object.keys(searchResults).length > 0 && menuOpen && (
                  <>
                    {searchResults.clients && (
                      <>
                        <MDBox
                          mx={1}
                          style={{ padding: "2px", fontSize: "15px" }}
                        >
                          {searchResults.clients.length !== 0
                            ? "Clients :"
                            : ""}
                        </MDBox>

                        {searchResults.clients.map((item, i) => (
                          <MenuItem
                            key={`${i}`}
                            onClick={() => navigate(`/clients/${item.id}`)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </>
                    )}
                    {searchResults.surveys && (
                      <>
                        <MDBox
                          mx={1}
                          style={{ padding: "2px", fontSize: "15px" }}
                        >
                          {searchResults.surveys.length !== 0
                            ? "Surveys :"
                            : ""}
                        </MDBox>

                        {searchResults.surveys.map((item, i) => (
                          <MenuItem
                            key={`${i}`}
                            onClick={() => navigate(`/surveys/${item.id}`)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </>
                    )}
                    {searchResults.projects && (
                      <>
                        <MDBox
                          mx={1}
                          style={{ padding: "2px", fontSize: "15px" }}
                        >
                          {searchResults.projects.length !== 0
                            ? "Projects :"
                            : ""}
                        </MDBox>

                        {searchResults.projects.map((item, i) => (
                          <MenuItem
                            key={`${i}`}
                            onClick={() => navigate(`/projects/${item.id}`)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </>
                    )}
                    {searchResults.projects.length === 0 &&
                      searchResults.surveys.length === 0 &&
                      searchResults.clients.length === 0 && (
                        <MDBox mb={1}>
                          <MenuItem>No result found</MenuItem>
                        </MDBox>
                      )}
                  </>
                )}
              </MDBox>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={{ display: { md: "display-block", lg: "none" } }}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </Stack>
            <Avatar src="/broken-image.jpg" />
          </Toolbar>
        </AppBar>
      </MDBox>
      <Outlet context={{ currentUser, setCurrentUser }} />
    </DashboardLayout>
  );
};

export default PrivateOutlet;
