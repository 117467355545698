import MDBox from "components/MDBox";
import React, { useState } from "react";
import "./index.css";
import { Stack } from "@mui/material";
function DashboardCard(props) {
  const [hover, setHover] = useState(false);
  const boxWidth = () => {
    if (hover) {
      return props.transformatiom;
    } else {
      return props.normalTransform;
    }
  };

  return (
    <MDBox>
      <div
        className="cardDetails"
        style={{
          background: props.Colour,
        }}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <div
          className="xyz"
          style={{
            background: `${props.iconBackground}`,
            width: "40px",
            height: "40px",
            transform: boxWidth(),
          }}
        >
          <img
            src={props.Icon}
            width="20px"
            height="20px"
            alt="icon"
            style={{
              filter:
                "invert(114%) sepia(100%) saturate(67%) hue-rotate(237deg) brightness(92%) contrast(97%)",
            }}
          />
        </div>

        <Stack direction="row" sx={{ width: "100%" }}>
          <div className="column">
            <div
              className="statusDesign"
              style={{ color: `${props.statusColour}` }}
            >
              {props.Status}
            </div>
            <div
              className="valueDesign"
              style={{ color: `${props.nameColour}` }}
            >
              {props.Value}
            </div>
          </div>
          <div
            style={{
              display: "inline-block",
              border: "1px solid black",
              // marginInline: "50px",
            }}
          ></div>
          <div className="column1">
            <div
              className="statusDesign"
              style={{ color: `${props.statusColour}` }}
            >
              {props.Maths}
            </div>
            <div
              className="valueDesign"
              style={{ color: `${props.nameColour}` }}
            >
              {props.MathsValue}
            </div>
          </div>
        </Stack>
      </div>
    </MDBox>
  );
}

export default DashboardCard;
