import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import Voqall_logo from "assets/logo/voqall_logo.svg";
import { Controller, useForm } from "react-hook-form";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  CircularProgress,
  Backdrop,
  Box,
} from "@mui/material";
import GetroleIdList from "hooks/roleList/GetRoleList";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Axios from "axios";
import TLinkApi from "services/tlink.api";
import Auth from "services/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

function Cover() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      roleId: "",
      password: "",
      confirmPassword: "",
    },
  });

  const { Roles, GetRoles, rolesMap, isLoading } = GetroleIdList();
  console.log(Roles);

  const [formIsLoading, setFormIsLoading] = useState(false);

  const save = async (value) => {
    try {
      console.log(value);
      const { data, status } = await Axios.post(TLinkApi.baseUrl + "/signup", value);
      setFormIsLoading(true);

      if (status === 200) {
        setFormIsLoading(false);
        Auth.saveAuthorizationToken(data?.token);
        enqueueSnackbar("Signed up successfully", {
          variant: "success",
        });
        navigate("/login");
      }
    } catch (err) {
      setFormIsLoading(false);
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
      });
    }
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={formIsLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CoverLayout
        sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}
      >
        <Card sx={{ width: "400px" }}>
          <MDBox variant="gradient" borderRadius="lg" mt={-2} p={3} mb={1} textAlign="center" />
          <MDBox variant="gradient" borderRadius="lg" mt={-14} p={4} mb={-4} textAlign="center">
            {/* <MDBox
              component="img"
              mt={2}
              src={Voqall_logo}
              alt="Admin App"
              width="200px"
              background=" #00AEEF"
            /> */}
          </MDBox>
          <MDBox pt={3} pb={3} px={3}>
            <form onSubmit={handleSubmit(save)} noValidate>
              <MDBox display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <MDBox mb={2} gridColumn="span 6">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: "First Name is required!",
                      validate: {
                        matchPattern: (v) =>
                          /^[a-zA-Z]+[a-zA-Z ]+$/.test(v) || "First Name must not contain any special character",
                      },
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="text"
                        id="firstName"
                        label="First Name"
                        {...register("firstName")}
                        variant="standard"
                        placeholder="Input First Name"
                        error={errors?.firstName ? true : false}
                        helperText={errors?.firstName ? errors.firstName.message : null}
                      />
                    )}
                  />
                </MDBox>
                <MDBox mb={1} gridColumn="span 6">
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: "Last Name is required!",
                      validate: {
                        matchPattern: (v) =>
                          /^[a-zA-Z]+[a-zA-Z ]+$/.test(v) || "Last Name must not contain any special character",
                      },
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="text"
                        id="lastName"
                        label="Last Name"
                        {...register("lastName")}
                        variant="standard"
                        placeholder="Input Last Name"
                        error={errors?.lastName ? true : false}
                        helperText={errors?.lastName ? errors.lastName.message : null}
                      />
                    )}
                  />
                </MDBox>
                <MDBox mt={-2} mb={1} gridColumn="span 12">
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required!",
                      validate: {
                        matchPattern: (v) =>
                          /\S+@\S+\.\S+/.test(v) || "Email address must be a valid address",
                      },
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="email"
                        id="email"
                        label="Email"
                        {...register("email")}
                        variant="standard"
                        placeholder="Input Email"
                        error={errors?.email ? true : false}
                        helperText={errors?.email ? errors.email.message : null}
                      />
                    )}
                  />
                </MDBox>
                <MDBox mt={-2} mb={1} gridColumn="span 12">
                  <Controller
                    name="roleId"
                    control={control}
                    rules={{
                      required: "Role is required!",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        required
                        variant="standard"
                        error={errors?.roleId ? true : false}
                        fullWidth
                        size="small"
                        sx={{
                          label: {
                            color: "rgba(0, 0, 0, 0.5)",
                            fontWeight: "400",
                          },
                        }}
                      >
                        <InputLabel>Role Id</InputLabel>
                        <Select
                          id="roleId"
                          value={value}
                          onChange={onChange}
                          label="Roll Id"
                          sx={selectStyle}
                          MenuProps={{
                            style: {
                              width: "150px",
                              height: "250px",
                            },
                          }}
                        >
                          {Roles?.map((value, i) => {
                            return (
                              <MenuItem value={value.role_id} key={i}>
                                {value.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText error>
                          {errors?.roleId ? errors.roleId.message : null}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox mt={-2} mb={1} gridColumn="span 12">
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Password is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="password"
                        id="password"
                        label="Password"
                        {...register("password")}
                        variant="standard"
                        placeholder="Input Password"
                        error={errors?.password ? true : false}
                        helperText={errors?.password ? errors.password.message : null}
                      />
                    )}
                  />
                </MDBox>
                <MDBox mt={-2} mb={1} gridColumn="span 12">
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: "Password is required!",
                      validate: (value) =>
                        value === watch("password") || "The passwords do not match",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="password"
                        id="confirmPassword"
                        label="Confirm Password"
                        {...register("confirmPassword")}
                        variant="standard"
                        placeholder="Confirm Password"
                        error={errors?.confirmPassword ? true : false}
                        helperText={errors?.confirmPassword ? errors.confirmPassword.message : null}
                      />
                    )}
                  />
                </MDBox>
                <MDBox mt={1} mb={1} gridColumn="span 12">
                  <MDButton type="submit" variant="gradient" color="info" fullWidth>
                    sign up
                  </MDButton>
                </MDBox>
                <MDBox mt={-1} mb={1} textAlign="center" gridColumn="span 12">
                  <MDTypography variant="button" color="text">
                    Already have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/authentication/sign-in"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </CoverLayout>
    </>
  );
}

export default Cover;
