import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode, width } =
    ownerState;

  const { transparent, background } = palette;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;

  let backgroundValue = darkMode
    ? background.sidenav
    : "linear-gradient(14.47deg, rgba(255, 166, 166, 0.5) 1.59%, rgba(255, 230, 230, 0.5) 46.59%, rgba(206, 219, 255, 0.5) 90.65%)";

  if (transparentSidenav) {
    backgroundValue = transparent.main;
  } else if (whiteSidenav) {
    backgroundValue = "rgba(255, 255, 255, 0.50)";
  }

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    "&::-webkit-scrollbar": {
      display: "none",
    },
    background: backgroundValue,
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.25)",
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
      width: width,
      scrollBar: {
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background: backgroundValue,
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: pxToRem(96),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
